/* navbar.css */
.navigation {
  height: 80px;
  width: 100%;
  display: none;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  padding-top: 0rem;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(43, 14, 132, 0.23);
  text-transform: uppercase;
  letter-spacing: 3px;
  z-index: 20;
}

.brand-name img {
 

  float: right;
  margin-top: 5px;

  width: 70%;
  height: 70px;
}
.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: none;
  padding: 0;
}
.navigation-menu li {
  /* removes default disc bullet for li tags and applies margin to left & right side */
  list-style-type: none;
  margin: 0 1rem;
}
.navigation-menu li a {
  /* increases the surface area of the anchor tag to span more than just the anchor text */
  text-decoration: none;
  display: block;
  width: 100%;
}
.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #245986;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  /* positions the icon to the right and center aligns it vertically */
  position: absolute;
  top: 70%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
.hamburger:hover {
  background-color: #2f5e86;
}
.custom-nav {
  width: 70px;

  height: 100%;

  float: left;
  position: fixed;
  padding: 0.5rem 0rem;
  background-image: url("./imgs/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 200px;
  color: white;
  box-shadow: 0 2px 2px 2px rgba(43, 14, 132, 0.23);

  z-index: 10;
}
.custom-nav a {
  color: black;
  text-decoration: none;
}

.hidden-nav {
  right: 0;
  top: 0;
  left: 70px;
  bottom: 0;
  z-index: 2000;
  background-image: url("./imgs/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  height: 100%;
  color: black;

  position: fixed;
  float: left;
  transition: all 0.5s ease 0s;
  padding: 7vh;

 
 
}
.logo-link {
  float: left;
  width: 80px;
  margin-top: 10px;
}
.logo-link img {
  margin: 0 !important;
}

hr {
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
}
.menu-toggle {
  font-size: 1rem;
  text-transform: uppercase;

  width: 100%;
  display: block;

  color: white;
  margin-bottom: 20px;
  margin-left: 12px;
  margin-top: 4.2rem;
}

.logo-nav img {
  align-content: center;
  justify-content: center;
 text-align: center;
  
  width: 20vw;
}

.middle-section {
  align-content: center;
  justify-content: center;
 
  font-size: 20px;
  font-weight: bold;
}

.bottom-section {
  align-content: center;
  justify-content: center;
  text-align: center;

  padding: 80px;
}

.bottom-section ul {
  list-style: none;
  display: flex;
  padding-left: 12rem;
}

.bottom-section li a {
  padding: 5px;
}

.bottom-section h4 {
  text-align: center;
}

.copyright {
  align-content: center;
  justify-content: center;
  display: flex;
  background: #60697c;
  text-align: center;
}
.wrapper {
  float: right;
  width: calc(100% - var(--sidebar-width));
  position: relative;
}
.h-100 {
  height: 100% !important;
}
.w-100 {
  width: 100% !important;
}

.social .fa-brands {
  padding: 10px;
  font-size: 20px;
  align-content: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  text-align: center;
  text-decoration: none;

  border-radius: 60%;
}
.fa-brands:hover {
  opacity: 0.7;
}

.fa-facebook-f {
  background: #3b5998;
  color: white;
}

.fa-linkedin {
  background: #3b5998;
  color: white;
}

@media screen and (max-width: 670px) {
  .navigation {
    height: 80px !important;
    margin-left: 0rem;
    display: block;
  }
  .hamburger {
    display: block;
  }

  .navigation-menu ul {
    /* navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar)  */
    position: absolute;
    top: 80px;
    left: 0;
    /* stacks the li tags vertically  */
    flex-direction: column;
    /* makes menu span full height and width  */
    width: 100%;
    height: calc(100vh - 77px);
    background:url("./imgs/bg.jpg");
    border-top: 1px solid black;
  }
  .navigation-menu li {
    /* centers link text and strips off margin  */
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    color: black;
    /* increases the surface area of the anchor tag to span the full width of the menu */
    width: 100%;
    padding: 1.5rem 0;
  }
  .navigation-menu li:hover {
    background-color: #eee;
  }

  .navigation-menu ul {
    /* previous styles */
    display: none;
  }
  .navigation-menu.expanded ul {
    display: block;
  }
  .custom-nav {
    display: none;
  }
}


.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  }
  
  .sidenav .link {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 15px;
  color: #818181;
  display: block;
  transition: 0.3s;
  }
  
  .sidenav .link:hover {
  color: #f1f1f1;
  }
  
  .sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  }
  