#page-content
{
    padding-left: 4rem;
}
.bread-crumb-g
{
    margin-top: 1rem;
    padding-left: 0.5rem;
}
.bread-crumb-g ul
{
   list-style: none;
   display: flex;
   letter-spacing: 0.45rem;
   text-transform: uppercase;
}

.bread-crumb-g li a
{
   text-decoration: none;
   color: black;
  

}
@media only screen and (max-width: 670px) {
    #page-content
{
    
    padding-left: 0px;

    
}
}