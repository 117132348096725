#services
{
    background: #e7e8e9;
    
   padding-left: 5rem;
   
   padding-top: 2.5rem;
}
.title_block {
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    
}
#services-inner
{
    padding-left: 4.5rem;
    padding-top: 3rem;
    padding-left: 8rem;
    padding-right: 8rem;
}
#services-inner  p
{
   font-family: "Mulish";
}
#services-inner  h3
{
   font-family: "Mulish";
   font-weight: bold;
}

.title_block::after {
    content: '';
    display: block;
    width:100px;
    height: 1.5px;
    background:  #C8DB29;
    margin: 5px auto 0 auto;
}
#services h3 
{
    
    color: #474646;
    opacity: 1;
    text-transform: uppercase;
    
}


.icon
{
    border-radius: 50px;
    background-color: #dbdbdf;
    width: 70px;
    height: 70px;
    padding: 15px;
    text-align: center;
}

.icon img {
    width: 2.5rem;
  }
 
 
  
  .content-holder {
    padding: 10px;
  }
.display-4
{
    position: relative;
    font-size:1.1rem;
    font-weight:normal ;
}





.links 
{
   
    color: rgb(111, 110, 110);
    text-decoration: none;
    font-size: 17px;
    line-height: 2px;
    
    
    
}
.service-card
{
   
        padding: 20px 13px;
        height: 100%;
        text-align: center;
        background: transparent;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 2px 0px 30px #b0b3b6;
    
}
.service-card 
 h3 {
    /* font-weight: 600; */
    font-size: 20px;
}

.banner-features h4 {
    font-size: 13px;
    font-weight: 700;
    display: inline-block;
    padding: 5px 8px 6px 8px;
    line-height: 1;
    border-radius: 4px;
    color: white;
}
.bg-1 {
    background-color: rgb(104, 148, 178) !important;
}
.banner-features {
    text-align: center;
    padding: 25px 15px;
    border-radius: 4px;
    border: 1px solid #cce7d0;
    -webkit-box-shadow: 20px 20px 54px rgb(0 0 0 / 3%);
    box-shadow: 20px 20px 54px rgb(0 0 0 / 3%);
}
.service-card p
{
    color: #474646;
    padding-top: 5px;
    padding-bottom: 3px;
    font-size: 17px;
    font-weight: 300;
}
.service-card h3{
     font-size: 16px;
    font-weight: 700;
    padding-top: 8px;
    
    text-align: center;
    color: #373938;
}
/* Mobile app develeopment page design */

#mobileAppPage
{
    padding-left: 4.5rem;
}
.mobileChat
{
    
}

/* End Mobile app develeopment page design */
@media screen  and (max-width:670px){
    #services
{
   background: #f8faff;
   padding: 0;
   padding-left: 0;
  
}

#services-inner
{
    padding-left: 0 !important;
    padding: 0;
    text-align: center;
    padding-right: 0 !important;
}
.section-content
{
    padding-left: 0;
}
.content-holder
{
    padding: 10px;
    
    text-align: left;
}
 
#mobileAppPage
{
    padding-left: 0;
}
   
}
@media screen  and (max-width:1000px){
  
 
     #services .holder
     {  
 
         width:11rem;
 
     }
 }

/*=========================Designing For Service Page=========================== */
