#about .right
{
    padding-top:5rem !important;
}

.sloganText 
{
    text-align: center;
    
    text-shadow: 1.3px 1.2px 2px rgb(197, 189, 189);
   
    font-size: 32px;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    line-height: 1.5;
    letter-spacing: 4px;
    padding: 10px;
   
}
 
.paragraphText
{
    font-size: 20px;
    font-family:  "Barlow",sans-serif;
    margin-top: 0;
    margin-bottom: 1rem;
    padding: 20px;
   
}
.achievements_holder
{
    border-right: 0.1px solid lightgray;
   padding: 5px;
   text-align: center;
   
    
 
   
}
.achievements_holder h3 
{
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    align-content: center;
    text-transform: uppercase;
    color: #20435A;
   
}

.achievements_holder p{
    font-size: 18px;
    font-family:  "Barlow",sans-serif;
}

.achivImg
{
    border-radius: 50px;
    background-color: #dbdbdf;
    width: 75px;
    height: 75px;
    margin: 0 auto;
    padding: 12px;
    position: relative;
    text-align: center;
    
    
    
}

.achivImg img 
{

    width: 3rem;
    
}
#about .left img
{
    border-radius: 30px;
    width: 80%;
}
#about .left
{}
#about .left p
{
color:#20435A;
font-size: 30px;
text-align: center;
padding: 130px 50px ;

}
#about .right p
{

font-size: 1rem;



}
#about
{
    padding-left: 5rem;
    padding: 20px;
}
#about p{
    font-family: "Barlow",sans-serif;
}


#aboutMain
{
    padding-left: 4.5rem;
}
.content-right img 
{
    border-radius: 8px;
    max-width: 100%;
    max-height: 100px;
   
}
.teamCard
{
    background-color: rgba(6, 5, 5, 0.05);
    box-shadow: 0 15px 35px rgba(90, 90, 90, 0.05);
    display: flex;
    border: 15px;
    height: 100%;
    padding: 20px;

}

.teamCard h3{
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
   
}

.teamCard p{
    font-size: 15px;
    text-align: center;
    padding-top: 10px;
}
.teamImg

{
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    border: 10px solid rgba(0, 0, 0, 0.25);
    justify-content: center;

}

.teamImg:hover img 

{

    opacity: 0.75;
   
}


.teamImg img
{

    width: 100%;
    height: 100%;
    position: absolute;

    object-fit: cover;
    top: 0;
    left: 0;
}


.content-left img 
{
    border-radius: 8px;
    max-width: 100%;
    max-height: 100px;
}
#about .right
{
    padding-top:1rem;
    border-left:0.1px solid lightgray ;
     height:400px;
}
@media screen and (max-width:800px) {
    #about
{
    padding-left: 0;
    
}




    }

    @media screen  and (max-width:600px){
    
    #about 
    {
        text-align: center;
    }
       #about .section-content{
        padding-left:0;
        padding:0;
        text-align: center;
        
        }
    
        #aboutMain
        {
            padding-left: 0;
        }
       
    }
    @media screen  and (max-width:766px){
    #about .left p
    {
    color:#0066A9;
    font-size: 30px;
    text-align: center;
    padding: 10px 10px ;
    
    }
    #about .right
{
    padding-top:1rem;
    border-left:0;
     height:400px;
}
}