 

#left-sol
{
   background-size: cover;
   padding-bottom: 30px;
   padding-top: 14rem;
   position: relative;
 
   z-index: 10;
   width: 100%;
   height: 100%;
   color: white;
   font-family: 'Source Sans Pro', sans-serif;
}
#left-sol ::before {
    background: rgba(183, 189, 195, 0.8) ;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  
  }

.right-sol
{
    
}


