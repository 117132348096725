#page-inner-content
{
    text-align: center;
    padding: 2rem;
}
#page-inner-content img 
{
    border-radius: 8px;
   
    max-width: 100%;
}
#page-inner-content h1
{
    
    font-family: Work Sans Bold,sans-serif;
   
    color: #353434;
    font-size: 1.5rem;
    line-height: 3rem;
    font-weight: 600;
   
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    margin-top: 2rem;
    
}
#page-inner-content p
{
    text-align: start;
   
}


.header-right img 
{
    border-radius: 8px;
    max-width: 100%;
    max-height: 300px;
   
}

.header-left img 
{
    border-radius: 8px;
    max-width: 100%;
    max-height: 300px;
}