#top-header-pages {
  background:rgb(65, 133, 178);

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 90vh;
  padding: 2.5rem;
  color: white;
}
#top-header-pages
{

  
   
    background-size: cover;
    padding-bottom: 30px;
    padding-top: 14rem;
    position: relative;
  
    z-index: 1;
    width: 100%;
    height: 100%;
    color: white;
    font-family: 'Source Sans Pro', sans-serif;
}
#top-header-pages::before {
  background: rgba(32, 67, 90, 0.8) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

}

.top-header-g-content img 
{
  width:3rem;
  
}

#top-header-pages .top-header-g-content
 {
  float: left;
  padding-left: 5rem;
}
.center-cube
{
  /* @keyframes duration | easing-function | delay |
iteration-count | direction | fill-mode | play-state | name */

position: relative;
animation: mover 1s infinite ;
animation-direction: alternate;
/* hence the sun only moves horizontally */
}


@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-4px); }
}

.left-cube
{
  /* @keyframes duration | easing-function | delay |
iteration-count | direction | fill-mode | play-state | name */

position: relative;
  animation:  rotation 20s infinite linear;
/* hence the sun only moves horizontally */
}

@keyframes rotation {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}





@media only screen and (max-width: 670px) {
  #top-header-pages {
    padding-left: auto;
  }
  #top-header-pages {
    background:rgb(65, 133, 178);
  
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    padding: 2.5rem;
    color: white;
  }
}
