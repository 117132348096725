html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
       
}

body 
{
    background: #f8faff;
    margin: 0;
    padding: 0;
     position: absolute;
  top: 0;
  transition: top 2s;
}

.section-heading p 
{
    
    color: #474646;
    opacity: 2;
    font-size: 1.25rem;
    font-weight: 300;
    
}
.section-heading 
{
    text-align: center;
    padding-bottom: 44px;
    
    
    line-height: 34px;
    position: relative;
   
    
   
}



#contact-us
{

    padding-top: 2rem;
    background: linear-gradient(
        470deg,
        rgb(84, 84, 84) 50%,
        transparent calc(40% + 1px)
      ),
      linear-gradient(rgb(90, 92, 92), rgb(72, 72, 72) 90%);
      color: white;
   
}

#contact-us blockquote p
{
    font-size: 25px!important;
    font-weight: 300;
    line-height: 32px!important;
    color: #fff!important;
    padding-bottom: 0px !important;
}
.h3Small
{
    font-weight: 500;
    font-size: 15px;
    
    letter-spacing: 2px;
    text-transform: capitalize;
}
.element
{
 
}
.boldParagraph
{
    font-size: 25px;
    font-family:  "Mulish",sans-serif;
    margin-top: 0;
    margin-bottom: 1rem;
    
    font-weight: 500;
    text-align: justify;
    text-align: center;
}
.impt-btn
{
    margin-bottom: 50px;
  text-align: justify;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 30px !important;
  color: #fff;
  padding: 11px 35px !important;
  border: 0px;
  background-color: #C8DB29;
  width: 11rem;
  padding: 0px;
  
}
#breaker
{
    padding-left: 3rem;
   
}
.footer-upper-section
 {
    background-image: url(./assets/imgs/wave.svg);
   
    margin-top: 50px;
    background-size:cover;
    width: 100%;
padding-left: 4.5rem;
    height: 100%;
    background-repeat: no-repeat;
}
.breaker-left
{
    padding-top:15rem;
   

    
}

.breaker-left p{
    line-height: 1.6 !important;
    font-size: 2.5rem;
    color: white;
    text-align: justify;
}
.footer-upper-section p {
    line-height: 1.6 !important;
}
.footer-upper-section-row .btn {
    margin-bottom: 50px;
    text-align: justify;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 30px !important;
    color: #fff;
    padding: 11px 35px !important;
    border: 0px;
    background-color: #C8DB29;
    width: 11rem;
   
    
}
.footer-upper-section-row .btn:hover
{
    background-color: white;
}
.section-content{
    padding-left:4.5rem !important;
    
    
    }
@media screen  and (max-width:670px){
    
       .section-content{
        padding-left:0 !important;
        padding:0;
        text-align: center;
        
        }
        #breaker
{
    padding-left: 0;
   

    
}
.footer-upper-section-row
{
    justify-content: center !important;
    text-align: center !important;
}
.breaker-left p{
    line-height: 1.6 !important;
    font-size: 2.5rem;
    color: white;
    text-align: center;
}
    
       
    }