#partners
{
    margin-top: 4rem;
    padding-left: 4rem;
   
}
.clients {
 
     padding: 5px 0;
   
     text-align: center;
   
   }
   
   
   
   .clients img {
   
    max-width: 65%;

  transition: all 0.4s ease-in-out;

  display: inline-block;

 
     
   
   }
   
   
   
   .clients img:hover {
   
     filter: none;
   
     transform: scale(1.15);
   
   }
   
   
  