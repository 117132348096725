body 
{
	margin: 0;
	padding: 0;
}

/*=====================HEADER====================== */
/*=====================NAVBAR====================== */
body 
{
    margin: 0;
    padding: 0;

}
.navbar 
{
    text-transform: uppercase;
    letter-spacing: 2px;
}