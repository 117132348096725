#contact-content
{
   
    justify-content: center;

    padding-left: 4rem;
    font-family: "Mulish",sans-serif;
}


#contact-content .right

{

    background: rgb(214, 214, 214);
    box-shadow: 0 0 7px rgba(18, 17, 17, 0.2);
    color: #60697c;
   

  
  padding: 20px;

  margin-left: -35px;

  border-radius: 8px;

  margin-top: 212px;

  width: 330px;
  height: 230px;

}
#contact-content .wrapper-icon
{
  border-radius: 0.375rem;
padding: 1rem;
  background-color: #3b5998;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
width: 4rem;
height: 4rem;
margin-top: 2px;
flex-shrink: 0;
}
.grow_text
{
  flex-grow: 1;
}
  .send-btn
{
 
 
  width: 130px;
  color: #171717;
  border-color: #090909;

}







#contact-content .left 
{
  background-color: #ddd;
  color: #60697c;
  padding: 30px;
  border-radius: 10px;
}



.cnt-frm-section-txt
{  padding: 40px;
  font-family: 'Roboto Serif', serif;
 text-align: center;
 color: linear-gradient(
    470deg,
    rgb(84, 84, 84) 50%,
    transparent calc(40% + 1px)
  ),
  linear-gradient(rgb(90, 92, 92), rgb(72, 72, 72) 90%);

}
.cnt-frm-section-txt h2
{
 font-size: 32px;
  font-weight: bold;
}
.cnt-frm-section-txt p
{
 font-size: 20px;
}

.cnt-form-section
{
  border-radius: 0 0 8px 8px;
    background: #fff;
    padding: 50px 40px 60px;
    box-shadow: 0 0 8px rgb(0 0 0 / 20%);

padding: 40px;

}
.office_holder
{
 
  font-family: "Mulish",sans-serif;

 color: black;
text-align: center;
padding: 10px;
width: 380px;

}

.office_holder .card
{
  background-color:  #ddd;
  border:#ddd;
}

.cnt-office h2
{
  color: linear-gradient(
    470deg,
    rgb(84, 84, 84) 50%,
    transparent calc(40% + 1px)
  ),
  linear-gradient(rgb(90, 92, 92), rgb(72, 72, 72) 90%);
}
.cnt-office h2
{
  font-size: 32px;
  font-weight: bold;
  
}
.cnt-office h3
{

  text-decoration: underline;
  
 
}




.img1 img 

{

  height: 200px;

  border-radius: 15px;

  width: 100%;
  background-color: #ddd;

}

.contact-imgs img 

{

  position: relative;

  

  

  width: 130px;

  height: 130px;

  border-radius: 50%;

  border: 7px solid darkcyan;

  

}

.img2 img 

{

  position: relative;

  display: block;

  margin-left: auto;

  margin-right: auto;

  z-index: 1;

  width: 120px;

  height: 120px;

  border-radius: 50%;

  border: 7px solid #fff;

  margin-top: -70px;

}

.card:hover .img2 img

{

  border-color: darkcyan;

  transition: .7s;

}



.main-text 

{

  text-transform: uppercase;

  font-weight: 900px;

  font-size: 20px;

  margin-top: 10px;

  letter-spacing: 0.1px;

  text-align: center;

  

}
@media only screen and (max-width: 992px)
{
  #contact-content .right
{
  margin-left:0;
  justify-content: center;
  text-align: center;
  align-content: center;
  margin-top: 20px;
  width: auto;
  height: auto;
 
 
}

}
@media only screen and (max-width: 670px)
{
    #contact-content
{
   
   

    padding-left: 0;
}
#contact-content .right
{
  margin-left:auto;
  justify-content: center;
  text-align: center;
  align-content: center;
  margin-top: 20px;

  width: auto;
  height: auto;
 
}

    
}