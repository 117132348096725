.overlapRight  
{
    border-radius: 100px;
    width: 10px;
    height: 10px;
    margin-top: 60px ;
    background-color:#E8E8E8;
    padding: 20px;
}

.overlapLeft
{
    background-color: #E8E8E8;
    padding: 30px;
    padding-top: 40px;
    margin-left: auto;
    margin-top:32px;
    border-radius: 100px;
    width: 160px;
    height: 160px;
    text-align: center;
    z-index: 1;
   
}

.overlapLeft p{
    color:#0066A9;
    font-weight: bold;
    font-size: 18px;

}

@media screen and (max-width:1400px)
{
    
        .overlapLeft
        {

            margin-left: auto;
            

        }
    
}