#footer {
  padding-top: 40px;
  background: #dbdada;

  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  padding-left: 65px;
}

#footer li Link {
  color: rgb(7, 7, 7);
  text-decoration: none;
  text-transform: uppercase;

  font-size: 0.8rem;
}

#footer h3 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(14, 13, 13);
}

#footer p {
  padding: 5px;

  font-size: 0.8rem;

  align-content: center;
  list-style: 1.2px;
}
#footer ul {
  list-style: none;
  align-content: left;
  margin: 0;
  padding: 0;
  color: rgb(4, 4, 4);
}

#footer li Link {
  line-height: 3px;
  color: rgb(14, 13, 13);
  letter-spacing: 1.2px;
}

.copyright {
  background: #20435A;
  opacity: 1;
  padding-top: 5px;
}
#footer .copyright p {
  color: white;
  padding: 3px;
  font-style: normal;
  text-align: center;

  align-content: center;
  justify-content: center;

  opacity: 0.7;

  font-size: 12px;

  text-transform: uppercase;
  letter-spacing: 4px;
}

#footer .fa-brands {
  padding: 10px;
  font-size: 20px;
  align-content: center;
  width: 40px;
  height: 40px;
  text-align: center;
  text-decoration: none;

  border-radius: 60%;
}
.fa-brands:hover {
  opacity: 0.7;
}

.fa-facebook-f {
  background: #3b5998;
  color: white;
}

.fa-twitter {
  background: #55acee;
  color: white;
}

#center-svg {
  text-align: center;
  padding-bottom: 40px;
  background-color: #145185;
  color: white;
}

#footer img {
  width: 15rem;
}
#footer ul li
{
  line-height: 2.1rem;

}
#footer ul li .nav-link
{
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 670px) {
  #footer {
    padding-left: 0;
  }
}
