.products
{
    background:#e7e8e9;
   
   
   margin: 0;
   padding: 0;
    
    font-family: 'Source Sans Pro', sans-serif;
    padding-left: 4rem;
    margin-top: 2rem;

}

.products-left
{
   padding: 50px;
    margin-top: 5rem;
    background:#20435A;
}

.products-left .section-heading{
    text-align: center;
    padding-bottom: 44px;
    font-family: Mulish;
    line-height: 34px;
}
.products-right
{
    padding: 30px;
}
.down
{
    margin-top: 50px;
}
.productsCard{
    padding: 15px 15px !important;;
        height: 95%;
        text-align: center;
        background: transparent;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 2px 0px 30px #b0b3b6;
        
}
.productsCard p
{
    color: #474646;
    padding-top: 5px;
    padding-bottom: 3px;
    font-size: 17px;
    font-weight: 300;
}

.card-title 
{
    font-size: 16px;
    font-weight: 700;
    padding-top: 8px;
    text-align: center;
    color: #373938;
}
.card
{
    padding: 20px;
    box-shadow: 3px 3px 3px 3px #bab8b8;
}
.text-justify
{
    text-indent: 1rem;
    text-align: justify;
}
.rounded-icon
{
    border-radius: 50px;
    background-color:#20435A;
    width: 60px;
    height: 60px;
    padding: 10px;
    text-align: center;
}
.rounded-icon img{
    width: 2.5rem;
}
@media screen  and (max-width:670px){
    
    .products .section-content
{
    padding: 0 ;
}
.products
{
    padding-left: 0;
}
    
}
