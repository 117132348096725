.header {
  background:#20435A;
  /*#071c51; 0c2860  #20435A*/
  background-repeat: no-repeat;
 
  background-position: center;
  color: #474646;
  opacity: 2;

  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
  padding-bottom: 10rem;
  margin: 0;
}
.header-left h1 {
 
  font-size: 40px;
  line-height: 3rem;
  font-weight: 520;
  font-family:  'PT Sans', sans-serif;
  text-transform: uppercase;

  color: rgb(253, 251, 251);
  
}
.header-left p {
  font-family: 'Barlow', sans-serif;
  font-size: 23px;
  line-height: 2rem;
  color: #f8f7f7;
  opacity: 2;
}
.header-logo img {
  
  
  width: 20vw;
}
.header-btn button {
  padding: 0.5rem;
  margin: 0.1rem;
}
.btn-primary {
  margin-bottom: 10px;
  text-align: justify;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-radius: 30px !important;
  color: #fff;
  padding: 11px 35px !important;
  border: 0px;
  background-color: #C8DB29;
  width: 11rem;
  padding: 0px;
  text-align: center;
 
 
}

.impt
{
  color:  #C8DB29;
  
}
.header-btn .btn-primary:hover {
  color: #fff;
  background: #3BBEF6;
 
}
.header-right img {
}
.header-holder {
 
  padding: 5px;
 
}
.scroll-btn {
  height: 40px;
  width: 30px;
  border: 2px solid #3389df;
  position: absolute;
  margin-top: 15px;
  border-radius: 50px;
  cursor: pointer;
  padding: 7px;
}

.scroll-btn a {
  color: #3389df;
}
.scroll-btn :hover {
  opacity: 0.7;
}
@media screen and (max-width: 1000px) {
  .scroll-btn {
    display: none;
  }

}
@media screen and (max-width: 670px) {
  .header

  {
    height: 100%;
  }
  .header-logo 
  {
    display: none;
  }
}
@media screen and (min-width: 1000px) {
  .header {
    padding-left: 2.5rem;
    
  }
  .header-holder {
   
  }
}
